













































































































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import { api_goods, goods } from "@/http/goods";
import PageHeader from "@/components/pageHeader/index.vue";
import { ElForm } from "element-ui/types/form";
import Upimg from "@/components/upimg/index.vue";
import Editor from "@/components/editor/index.vue";
import { api_goodsCategory, goodsCategory } from "@/http/goodsCategory";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
@Component({
  components: {
    PageHeader,
    Upimg,
    Editor,
  },
})
export default class extends Vue {
  info: goods = {
    id: "",
    name: "",
    cover: "",
    type_clothing: "",
    service_scope: "",
    number: 0,
    content: "",
    price: 0,
    stock: "",
    start_time: "",
    fish_time: "",
    status: 1,
    sales: 0,
    goods_type: 0,
    gold: 1,
    diamond: 1,
    sku: "",
  };
  coverUrls: string[] = [];
  rules = {
    name: [{ required: true, message: "请输入" }],
    cover: [{ required: true, message: "请上传封面" }],
    content: [{ required: true, message: "请填写内容" }],
    price: [{ required: true, message: "请填写价格" }],
    // time: [{ required: true, message: "请选择状态" }],
    service_scope: [{ required: true, message: "请填写服务范围" }],
    type_clothing: [{ required: true, message: "请选择衣服种类" }],
    number: [{ required: true, message: "请填写清洗数量" }],
    stock: [{ required: true, message: "请填写库存" }],
  };
  async submit() {
    await (this.$refs["form"] as ElForm | undefined)?.validate();
    await (this.$refs["form2"] as ElForm | undefined)?.validate();
    if (this.id) {
      this.info.sku = JSON.stringify(this.skus);
      await api_goods.edit(this.info);
    } else {
      if (this.info.start_time == "" && this.info.fish_time == "") {
        delete this.info.start_time;
        delete this.info.fish_time;
      }
      this.info.sku = JSON.stringify(this.skus);
      await api_goods.create(this.info);
    }
    // this.$message.success("提交成功");
    this.$router.go(-1);
  }

  async get_info() {
    this.info = await api_goods.get_info(this.id as number);
    this.info.gold = Number(this.info.gold);
    this.info.diamond = Number(this.info.diamond);
    if (typeof this.info.sku === "string") {
      // 尝试解析 JSON 字符串
      try {
        this.skus = JSON.parse(this.info.sku) as any[];
        // 过滤掉price里面的元
        this.skus.forEach((item) => {
          item.price = String(item.price).replace("元", "");
        });
      } catch (error) {
        console.error("Error parsing JSON:", error);
        // 如果解析失败，可以设定一个默认值或处理错误
        this.skus = [];
      }
    }
    // 处理封面图
    if (typeof this.info.cover === "string") {
      const domain = "https://images.xiyuebiaoju.com/"; // 替换为你的域名
      this.coverUrls = this.info.cover
        .split(",")
        .map((url) => domain + url.trim());
    }
  }
  // 监听图片数据
  @Watch("coverUrls")
  setCover(val: string[]) {
    const domain = "https://images.xiyuebiaoju.com/";
    this.info.cover = val.map((url) => url.replace(domain, "")).join(", ");
  }
  // 监听状态
  @Watch("info.status")
  setStatus(val: number) {
    if (val == 1) {
      this.info.fish_time = null;
      this.info.start_time = null;
    }
  }
  get id(): undefined | number {
    return this.$route.query.id as any;
  }
  // 获取用户列表
  categoryList: any[] = [];

  async get_category_list() {
    const res = await api_goodsCategory.get_list({ page_size: 200 });
    const list: any[] = [];
    // res.results.forEach((item:any)=>{
    //     item.value = item.city
    // })
    this.categoryList = list.concat(res.results);
  }

  created() {
    this.get_category_list();
    if (this.id === undefined) return;
    this.get_info();
  }
  currentSku: any = {};
  dialogVisible: boolean = false;
  skus: any[] = [];
  addSku() {
    const newSku: any = {
      name: "",
      price: null,
      stock: null,
      key: this.generateUUID(),
    };
    this.skus.push(newSku);
    this.currentSku = newSku;
    this.dialogVisible = true;
  }
  removeSku(sku: any) {
    const index = this.skus.findIndex((item: any) => item.key === sku.key);
    if (index !== -1) {
      this.skus.splice(index, 1);
    }
  }
  selectRow(row: any) {
    this.currentSku = Object.assign({}, row);
    this.dialogVisible = true;
  }
  updateSku() {
    if (
      this.currentSku.stock == "" ||
      this.currentSku.name == "" ||
      this.currentSku.price == ""
    ) {
      return this.$message.error("请填写完整信息");
    }
    const index = this.skus.findIndex(
      (item: any) => item.key === this.currentSku.key
    );
    if (index !== -1) {
      this.skus[index] = this.currentSku;
      Vue.set(this.skus, index, this.currentSku);
    }
    this.dialogVisible = false;
  }
  closeSku() {
    this.dialogVisible = false;
    // 过滤掉为空的
    this.skus = this.skus.filter(
      (item: any) => item.name && item.price && item.stock
    );
  }
  generateUUID() {
    return uuidv4();
  }
}


import { Http } from "@/http"
import { Id, res_list, req_list } from '@/types/global'

export interface add_goodsCategory{
    name:string
    desc:string,
    remark:string
}
export interface goodsCategory extends add_goodsCategory{
    id:Id
    create_time:string
    update_time:string
}

class HttpGoodsCategory extends Http{
	get_list = (data:req_list) => {
		return this.get<res_list<goodsCategory>>({...data})
	}

    get_info = (id:number) => {
        return this.get<goodsCategory>({},`/admin/category/${id}/`)
    }

    create = (data:add_goodsCategory) => {
        return this.post(data)
    }

    edit = ({id,...data}: goodsCategory) => {
        return this.patch(data,`/admin/category/${id}/`)
    }

    del_one = (id:number) => {
        return this.delete(id)
    }
}

export const api_goodsCategory =  new HttpGoodsCategory('/admin/category/')
